import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'kapta';

  constructor(private translate: TranslateService) {
    const browserLang = navigator.language.split('-')[0]; // Detectar idioma del navegador
    const defaultLang = ['en', 'es'].includes(browserLang) ? browserLang : 'es'; // Verificar idioma válido
    translate.setDefaultLang(defaultLang); // Establecer idioma por defecto
    translate.use(defaultLang); // Usar idioma detectado
  }

  changeLanguage(lang: string) {
    this.translate.use(lang); // Cambiar idioma
  }
}


