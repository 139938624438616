import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WelcomeModule } from './welcome/welcome.module';



const routes: Routes = [
  { path: 'welcome',  loadChildren: () => import('./welcome/welcome.module').then(m => m.WelcomeModule) },
  { path: '**', redirectTo: 'auth' },
  { path: '', pathMatch: 'full', redirectTo: 'welcome' }

];


@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: "ignore",
  anchorScrolling:'enabled',
  scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
